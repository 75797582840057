import {Dialog} from '@material-ui/core';
import Config from 'common/Config';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useFrame} from 'react-frame-component';
import {connect} from 'react-redux';
import UserSelectors from 'store/user/UserSelectors';

const JiraIssueCollector = (props) => {
    const iframe = useRef();

    const [open, setOpen] = useState(false);
    const [iframeEvent, setIframeEvent] = useState(false);

    useEffect(() => {
        setOpen(props.open || true);
    }, [props.open]);

    useEffect(() => {
        const handler = event => {
            if (event.origin !== location.origin || event.data?.handler !== 'parentDoc') {
                return;
            }
            setIframeEvent(event.data);

        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, []);

    useEffect(() => {
        if (iframe.current) {
            const w = iframe.current.contentWindow;
            const d = w.document;
            if (iframeEvent.type === 'mounted') {
                const script2 = d.createElement('script');
                script2.async = true;
                script2.src = Config.get('app.jiraCollectorUrl');
                script2.type = 'text/javascript';
                d.head.appendChild(script2);
                w.ATL_JQ_PAGE_PROPS = {
                    'triggerFunction': function (showCollectorDialog) {
                        w.$(document).ready(() => {
                            w.$('#atlwdg-container').empty();
                            showCollectorDialog();
                            w.sendEvent('parentDoc', 'modal-ready');
                        });
                    },
                    'fieldValues': {
                        recordWebInfoConsent: 'on',
                        fullname: props.user?.fullname || 'Visitor (not logged in)',
                        email: props.user?.email || '',
                        summary: '[Web] - ',
                        description: '' +
                            '*Summary*\n' +
                            '\n' +
                            '_(Summarize the bug encountered concisely)_\n' +
                            '\n' +
                            '*Steps to reproduce*\n' +
                            '\n' +
                            '_(How one can reproduce the issue - this is very important)_\n' +
                            '\n' +
                            '*What is the current bug behavior?*\n' +
                            '\n' +
                            '_(What actually happens)_\n' +
                            '\n' +
                            '*What is the expected correct behavior?*\n' +
                            '\n' +
                            '_(What you should see instead)_\n' +
                            '\n' +
                            '*Relevant logs and/or screenshots*' +
                            '' + '\n',
                        environment: '' +
                            '_Please do not edit below this line_' + '\n' +
                            '----' + '\n' +
                            '\n*================ Details ================*' + '\n' +
                            '\n' + '{code:title=Details|theme=FadeToGrey|linenumbers=true|language=javascript|firstline=0001}\n' +
                            JSON.stringify({
                                url: window.location.href,
                                user_id: props.user?.id,
                                location_id: props.user?.location_id,
                                organization_id: props.user?.organization?.id,
                                role: props.user?.roles?.[0]?.name + (props.user?.roles?.[0]?.isInternal ? '(Internal)' : '')
                            }, null, 2) + '\n' +
                            '{code}' + '\n' +
                            '\n' + '{code:title=Error|theme=FadeToGrey|linenumbers=true|language=javascript|firstline=0001}\n' +
                            props.error + '\n' +
                            '{code}' + '\n' +
                            '\n' + '{code:title=Error Stack|theme=FadeToGrey|linenumbers=true|language=javascript|firstline=0001}\n' +
                            props.errorInfo.componentStack + '\n' +
                            '{code}' + '\n'
                    }
                };
            }
            else if (iframeEvent.type === 'ready') {
                // console.log('frame ready')
            }
            else if (iframeEvent.type === 'modal-ready') {
                w.modalReady();
            }
            else if (iframeEvent.type === 'modal-closed') {
                setOpen(false);
            }
        }
    }, [iframeEvent]);

    return (
        <Dialog open={open} maxWidth={'md'} fullWidth={true} keepMounted={false}>
            {open && (
                <iframe
                    ref={iframe}
                    loading={'eager'}
                    style={{
                        border: 0,
                        width: '100%',
                        minHeight: 600
                    }}
                    src={'/jira/jiraIssueTracker.html'}
                />
            )}
        </Dialog>
    );
};

JiraIssueCollector.propTypes = {
    error: PropTypes.any,
    errorInfo: PropTypes.any,
    props: PropTypes.any,
    open: PropTypes.any,
    user: PropTypes.object
};

export default connect(state => ({
    user: UserSelectors.getUser(state)
}))(JiraIssueCollector);
