export default ({
    USER_STATUS: {
        INACTIVE: 1,
        CHANGE_PASSWORD: 2,
        ACTIVE: 3,
        LOCKED: 4,
        BLOCKED: 5,
        DELETED: 6
    },
    VITAL_TYPES: {
        HR: 'heart-rate',
        BP: 'blood-pressure',
        Height_Weight_BMI: 'height-and-weight-bmi'
    },
    ASSESSMENT_STATUS: {
        COMPLETE: 'complete',
        INCOMPLETE: 'incomplete',
        READY_TO_START: 'ready-to-start'
    },
    LOGIN_TYPES: {
        EMAIL: 'email',
        PHONE: 'phone',
        PROGRAM: 'program',
        NO_LOGIN: 'no-login'
    },
    ORGANIZATION_PREFERENCES: {
        ALLOW_SHARING_RESULTS: 'allow_sharing_results',
        ANALYSIS_WORKER_QUEUE: 'analysis_worker_queue',
        APP_SURVEY_SLUG: 'app_survey_slug',
        AR_BACK_IN_TIME: 'ar_back_in_time',
        AR_DAY_OUT: 'ar_day_out',
        BLOOD_PRESSURE_ARM_SELECTION: 'blood_pressure_arm_selection',
        DISALLOW_NEGATIVE_CREDIT: 'disallow_negative_credit',
        DISPLAY_ARTICLES: 'display_articles',
        DISPLAY_PATIENT_RESULTS: 'display_patient_results',
        DO_NOT_ALLOW_AR_SKIP: 'do_not_allow_ar_skip',
        EYE_TRACKING: 'eye_tracking',
        HIDE_PATIENT_FIRST_NAME: 'hide_patient_first_name',
        MARKETING_CONSENT: 'marketing_consent',
        MOTOR_ACTIVITY_1: 'motor_activity1',
        MOTOR_ACTIVITY_2: 'motor_activity2',
        RECERTIFICATION_USERS: 'recertification_users',
        REPORT_VERSION: 'report_version',
        REQUIRE_PARTICIPANT_ID: 'require_participant_id',
        SESSION_INACTIVITY_TIMEOUT: 'session_inactivity_timeout',
        SESSION_LOGOUT_TIMEOUT: 'session_logout_timeout',
        SPEECH_TEST: 'speech_test',
        SPEECH_TEST_2: 'speech_test2',
        TAP_AND_DRAW: 'tap_and_draw',
        TAP_AND_DRAW_2: 'tap_and_draw2',
        SCORE_TYPE: 'score_type'
    }
});
